import gsap from "gsap";
import configAnimation from "./config/animation";
import imagesLoaded from "imagesloaded";

export default function Loading(_callback) {

    let elMain = document.querySelector("#main");

    let elLoading = document.querySelector("#loading");

    const imgLoad = imagesLoaded(elMain, {
        background: true
    });
    // Images done loaded
    imgLoad.on("always", () => {
        const tl_loadcomplete = gsap.timeline();

        tl_loadcomplete.to(elLoading, {
            opacity: 0,
            duration: configAnimation.global_duration - 0.8,
            ease: "none",
            display: "none",
        });

        _callback();
    });

}