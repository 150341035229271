import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import configAnimation, { anim_revealBottom, anim_fadeBottom } from "./config/animation";

export default function Universal() {

    // Title
    try {
        gsap.utils.toArray(".reveal-char").forEach((el, i) => {
            const char = el.querySelectorAll(".char");
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(char, anim_revealBottom);
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".reveal-line").forEach((el, i) => {
            const line = el.querySelectorAll(".line");
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(line, {
                yPercent: 100,
                stagger: 0.18,
                force3D: true
            });
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".fade-bottom").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(el, anim_fadeBottom);
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".reveal-image").forEach((el, i) => {
            const elRevealimg_img = el.querySelector("img");

            // On scroll trigger
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom+=40%"
                },
            });

            tl_universal.set(
                el, {
                    autoAlpha: 1,
                });

            tl_universal.from(
                el, {
                    yPercent: 100,
                    duration: configAnimation.global_duration,
                });

            tl_universal.from(
                elRevealimg_img, {
                    yPercent: -100,
                    scale: 1.4,
                    duration: configAnimation.global_duration,
                }, 0);
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".parallax .parallax-image").forEach((section, i) => {
            gsap.set(section, {
                yPercent: -30
            });

            gsap.to(section, {
                scrollTrigger: {
                    onUpdate: (self) => {
                        gsap.set(section, {
                            yPercent: self.progress * 50 - 30
                        });
                    },
                    trigger: section,
                    start: "top bottom",
                    end: "200% center",
                    once: false,
                    scrub: true
                }
            });

            gsap.from(section, {
                scale: 1.25,
                duration: 2,
                ease: "expo.out",
                scrollTrigger: {
                    trigger: section,
                }
            });
        });
    } catch (error) {}

}