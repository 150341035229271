// Dependency
import {
    Utility
} from "./config/animation";
import Smoothscroll from './locomotive';
import Default from './config/default';

import Universal from "./universalanim";
import Loading from "./loading";
import Header from "./header";

function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export default function Init() {
    Default();
    Utility();
    Smoothscroll();

    Loading(() => {
        Header();
        Universal();
    })

}

docReady(() => {
    Init();
});